<template>
  <div>
    <b-modal
      ref="refFormModalBanner"
      title="จัดการ Banner"
      :ok-title="form.id ? 'แก้ไข' : 'เพิ่ม'"
      cancel-title="ยกเลิก"
      modal-class="modal-primary"
      size="md"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @ok="onSubmit"
      @cancel="resetForm"
    >
      <validation-observer ref="refFormBanner">
        <b-form>
          <b-row>
            <b-col sm="12">
              <b-form-group label="ชื่อรูป">
                <validation-provider #default="{ errors }" name="banner_name" rules="required">
                  <b-form-input v-model="form.name" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-form-group label="ลิงค์ไปที่">
                <validation-provider #default="{ errors }" name="banner_link_to" rules="url">
                  <b-form-input v-model="form.link_to" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-form-group label="URL รูปขนาด Mobile (แนะนำ 720x246)">
                <validation-provider #default="{ errors }" name="banner_image_mobile" rules="required|url">
                  <b-form-input v-model="form.image_mobile.url" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <div v-if="form.image_mobile.url && !errors[0]" class="mt-50">
                    <box-image-preview-widget
                      :src="form.image_mobile.url"
                      @changeSize="obj => onChangeSizeImage(obj, 'mobile')"
                    />
                    <span>ขนาด {{ form.image_mobile.width }}x{{ form.image_mobile.height }}</span>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-form-group label="URL รูปขนาด Desktop (แนะนำ 1976x688)">
                <validation-provider #default="{ errors }" name="banner_image_desktop" rules="required|url">
                  <b-form-input v-model="form.image_desktop.url" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <div v-if="form.image_desktop.url && !errors[0]" class="mt-50">
                    <box-image-preview-widget
                      :src="form.image_desktop.url"
                      @changeSize="obj => onChangeSizeImage(obj, 'desktop')"
                    />
                    <span>ขนาด {{ form.image_desktop.width }}x{{ form.image_desktop.height }}</span>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        id: null,
        name: '',
        image_desktop: {
          url: '',
          width: null,
          height: null,
        },
        image_mobile: {
          url: '',
          width: null,
          height: null,
        },
        link_to: '',
        index: null,
      },
    }
  },
  methods: {
    open() {
      this.$refs.refFormModalBanner.show()
    },
    close() {
      this.$refs.refFormModalBanner.hide()
    },
    resetForm() {
      this.form = this.coppyParam({
        id: null,
        name: '',
        image_desktop: {
          url: '',
          width: null,
          height: null,
        },
        image_mobile: {
          url: '',
          width: null,
          height: null,
        },
        link_to: '',
        index: null,
      })
    },
    updateForm(values) {
      this.form = this.coppyParam(values)
    },
    onChangeSizeImage(sizeObj, key) {
      if (key === 'desktop') {
        this.form.image_desktop.width = sizeObj.width
        this.form.image_desktop.height = sizeObj.height
      }

      if (key === 'mobile') {
        this.form.image_mobile.width = sizeObj.width
        this.form.image_mobile.height = sizeObj.height
      }
    },
    async onSubmit(bvEvent) {
      bvEvent.preventDefault()
      const validate = await this.$refs.refFormBanner.validate()
      if (!validate) return
      this.$emit('onConfirm', this.form)
    },
  },
}
</script>

<style lang="scss" scoped></style>
