var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"refFormModalBanner",attrs:{"title":"จัดการ Banner","ok-title":_vm.form.id ? 'แก้ไข' : 'เพิ่ม',"cancel-title":"ยกเลิก","modal-class":"modal-primary","size":"md","hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":""},on:{"ok":_vm.onSubmit,"cancel":_vm.resetForm}},[_c('validation-observer',{ref:"refFormBanner"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"ชื่อรูป"}},[_c('validation-provider',{attrs:{"name":"banner_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"ลิงค์ไปที่"}},[_c('validation-provider',{attrs:{"name":"banner_link_to","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.link_to),callback:function ($$v) {_vm.$set(_vm.form, "link_to", $$v)},expression:"form.link_to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"URL รูปขนาด Mobile (แนะนำ 720x246)"}},[_c('validation-provider',{attrs:{"name":"banner_image_mobile","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.image_mobile.url),callback:function ($$v) {_vm.$set(_vm.form.image_mobile, "url", $$v)},expression:"form.image_mobile.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.form.image_mobile.url && !errors[0])?_c('div',{staticClass:"mt-50"},[_c('box-image-preview-widget',{attrs:{"src":_vm.form.image_mobile.url},on:{"changeSize":function (obj) { return _vm.onChangeSizeImage(obj, 'mobile'); }}}),_c('span',[_vm._v("ขนาด "+_vm._s(_vm.form.image_mobile.width)+"x"+_vm._s(_vm.form.image_mobile.height))])],1):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"URL รูปขนาด Desktop (แนะนำ 1976x688)"}},[_c('validation-provider',{attrs:{"name":"banner_image_desktop","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.image_desktop.url),callback:function ($$v) {_vm.$set(_vm.form.image_desktop, "url", $$v)},expression:"form.image_desktop.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.form.image_desktop.url && !errors[0])?_c('div',{staticClass:"mt-50"},[_c('box-image-preview-widget',{attrs:{"src":_vm.form.image_desktop.url},on:{"changeSize":function (obj) { return _vm.onChangeSizeImage(obj, 'desktop'); }}}),_c('span',[_vm._v("ขนาด "+_vm._s(_vm.form.image_desktop.width)+"x"+_vm._s(_vm.form.image_desktop.height))])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }