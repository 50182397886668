<template>
  <div>
    <slide-banner />
    <br />
    <b-row>
      <b-col sm="12">
        <b-card title="จัดการข้อมูล สไลด์">
          <!-- <b-card-text>
          ข้อมูล
        </b-card-text> -->
          <!-- <label for="textarea-default"></label> -->
          <b-form-textarea id="textarea-default" placeholder="เพิ่มข้อมูล" rows="3" v-model="slide_data" />
        </b-card>
      </b-col>

      <b-col sm="12" class="text-right">
        <b-button variant="outline-info" @click="clickUpdateOrCreate()">
          บันทึก
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import SlideBanner from './components/SlideBanner.vue'

export default {
  components: {
    BCardCode,
    BCardText,
    BFormTextarea,
    SlideBanner,
  },
  data() {
    return {
      codeDefault: '',
      slide_data: '',
    }
  },
  created() {
    this.query()
  },
  methods: {
    async clickUpdateOrCreate() {
      await this.api.post('/api/admin/slide-data', { input_data: this.slide_data }, true)
    },

    async query() {
      this.slide_data = await this.api.get('/api/admin/slide-data')
    },
  },
}
</script>
