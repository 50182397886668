<template>
  <div>
    <!-- <b-overlay :show="isLoading" spinner-small> -->
    <b-card>
      <table-local-v-1-widget
        :items="bannerList"
        :columns="columns"
        :loading="isLoading"
        :actions="actions"
        title="ข้อมูลสไลด์ Banner"
        @editBanner="editBanner"
        @deleteBanner="deleteBanner"
      >
        <template #cardHeader>
          <div class="d-flex justify-content-end mb-50" style="gap: 0.5rem">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover
              title="เพิ่มข้อมูล Banner"
              variant="flat-primary"
              class="btn-icon"
              @click="addBanner"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover
              title="จัดการลำดับ Banner"
              variant="flat-primary"
              class="btn-icon"
              @click="manageSortBanner"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
          </div>
        </template>

        <template #id="{row}">
          <span v-b-tooltip.hover :title="row.id">{{ `${row.id}`.slice(0, 8) }}...</span>
        </template>
        <template #image_desktop="{row}">
          <div v-if="row.image_desktop">
            <img :src="row.image_desktop.url" alt="" class="banner-image" @click="gZoomImage" />
            <br />
            <small>{{ row.image_desktop.width }}x{{ row.image_desktop.height }}</small>
          </div>
          <span v-else>-</span>
        </template>

        <template #image_mobile="{row}">
          <div v-if="row.image_mobile">
            <img :src="row.image_mobile.url" alt="" class="banner-image" @click="gZoomImage" />
            <br />
            <small>{{ row.image_mobile.width }}x{{ row.image_mobile.height }}</small>
          </div>
          <span v-else>-</span>
        </template>

        <template #link_to="{row}">
          <a v-if="row.link_to" :href="row.link_to" target="_blank">{{ row.link_to }}</a>
          <span v-else>-</span>
        </template>
      </table-local-v-1-widget>
    </b-card>

    <!-- </b-overlay> -->

    <form-modal-banner ref="refFormModalBanner" @onConfirm="onModalBannerConfirm" />
    <drag-and-drop-modal-banner ref="refDragAndDropModalBanner" @onConfirm="onConfirmSortBanner" />
  </div>
</template>

<script>
import FormModalBanner from './FormModalBanner.vue'
import DragAndDropModalBanner from './DragAndDropModalBanner.vue'

export default {
  components: {
    FormModalBanner,
    DragAndDropModalBanner,
  },
  data() {
    return {
      bannerList: [],
      isLoading: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'custom',
        },
        {
          label: 'ชื่อรูป',
          field: 'name',
        },
        {
          label: 'รูปขนาด Mobile',
          field: 'image_mobile',
          type: 'custom',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'รูปขนาด​ Desktop',
          field: 'image_desktop',
          type: 'custom',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'ลิงค์ไปที่',
          field: 'link_to',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          type: 'custom',
        },
        // {
        //   label: 'วันที่สร้าง',
        //   field: 'create_at',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        // },
        // {
        //   label: 'วันที่แก้ไขล่าสุด',
        //   field: 'update_at',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        // },
      ],
      actions: [
        { keyEmit: 'editBanner', label: 'แก้ไข', iconName: 'EditIcon' },
        { keyEmit: 'deleteBanner', label: 'ลบ', iconName: 'TrashIcon' },
      ],
    }
  },

  created() {
    this.fetchAllBannerList()
  },
  methods: {
    async fetchAllBannerList() {
      // const newMockList = [...new Array(5)].map((_, idx) => {
      //   const id = idx + 1
      //   return {
      //     id: `id-${id}`,
      //     index: idx,
      //     name: `image ${id}`,
      //     link_to: 'https://swiperjs.com/demos',
      //     image_desktop: {
      //       url: 'https://icms-image.slatic.net/images/ims-web/c9eb5f73-094f-4c93-89a5-857e40eee5f5.jpg',
      //       width: 1976,
      //       height: 688,
      //     },
      //     image_mobile: {
      //       url:
      //         'https://lzd-img-global.slatic.net/g/ot/homepage/2f1fe0849742ab075583b45a6f10280a.jpeg_720x720q80.jpg_.webp',
      //       width: 720,
      //       height: 246,
      //     },
      //   }
      // })

      try {
        this.isLoading = true
        const resp = await this.api.getV2('api/public/banners')
        if (resp.code === 200) {
          this.bannerList = [...resp.data]
        }
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },
    addBanner() {
      this.$refs.refFormModalBanner.resetForm()
      this.$refs.refFormModalBanner.open()
    },
    editBanner(values) {
      // console.log('editBanner', values)
      this.$refs.refFormModalBanner.open()
      this.$refs.refFormModalBanner.updateForm(values)
    },
    async deleteBanner(values) {
      const isConfirm = await this.gCheckConfirmV1('ยืนยันลบ', `ต้องการลบ Banner : ${values.name} ใช่หรือไม่`)

      if (!isConfirm) return
      this.gOpenPageLoading()
      const resp = await this.api.deleteV3(`api/admin/banner-data/${values.id}`, this)
      this.gClosePageLoading()

      if (resp?.code === 405) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.data?.message, 'danger')
        return
      }

      if (resp.code === 200) {
        this.gDisplayToast('ลบ Banner สำเร็จแล้ว')
        this.fetchAllBannerList()
      }
    },
    async onModalBannerConfirm(values) {
      const isConfirm = await this.gCheckConfirmV1()

      if (!isConfirm) return
      this.gOpenPageLoading()
      let resp
      let titleSuccessToast = ''
      const isEdit = !!values.id

      if (isEdit) {
        resp = await this.api.patchV2('api/admin/banner-data/update', values, this)
        titleSuccessToast = 'แก้ไขข้อมูลสำเร็จ'
      } else {
        resp = await this.api.postV2(
          'api/admin/banner-data/create',
          { ...values, index: this.bannerList.length + 1 },
          this,
        )
        titleSuccessToast = 'เพิ่มข้อมูลสำเร็จ'
      }
      this.gClosePageLoading()

      // console.log('onModalBannerConfirm', resp)

      if (resp?.code === 405) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.data?.message, 'danger')
        return
      }

      if (resp?.code === 200) {
        this.gDisplayToast(titleSuccessToast)
        this.fetchAllBannerList()
        this.$refs.refFormModalBanner.close()
      }
    },
    manageSortBanner() {
      if (this.bannerList.length < 2) {
        this.gDisplayToast('ไม่สามารถดำเนินการต่อได้', 'รายการ Banner ที่มีต้องมากกว่า 1 ถึงจะใช้งานได้', 'danger')
        return
      }

      this.$refs.refDragAndDropModalBanner.open()
      this.$refs.refDragAndDropModalBanner.updateList(this.bannerList)
    },
    async onConfirmSortBanner(newList) {
      const isConfirm = await this.gCheckConfirmV1()

      if (!isConfirm) return

      const sortList = [...newList].map((v, idx) => ({ ...v, index: idx + 1 }))

      this.gOpenPageLoading()
      const resp = await this.api.putV2(
        'api/admin/banner-data/update-index',
        {
          index: sortList,
        },
        this,
      )
      this.gClosePageLoading()
      // console.log('onConfirmSortBanner', resp)

      if (resp?.code === 405) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.data?.message, 'danger')
        return
      }

      if (resp?.code === 200) {
        this.$refs.refDragAndDropModalBanner.close()
        this.fetchAllBannerList()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100px;
  cursor: pointer;
}
</style>
