<template>
  <div>
    <b-modal
      ref="refDragAndDropModalBanner"
      title="จัดการลำดับ Banner"
      ok-title="แก้ไข"
      cancel-title="ยกเลิก"
      modal-class="modal-primary"
      size="md"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @ok="onSubmit"
      @cancel="resetList"
    >
      <draggable v-model="localList" tag="ul" class="list-group list-group-flush cursor-move">
        <transition-group type="transition" name="flip-list">
          <b-list-group-item v-for="item in localList" :key="item.id" tag="li">
            <feather-icon icon="ListIcon" size="21" />
            <img
              v-if="item.image_mobile"
              :src="item.image_mobile.url"
              alt=""
              class="banner-image mx-2"
              @click="gZoomImage"
            />
            <strong> {{ item.name || 'ไม่พบข้อมูล' }}</strong>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      localList: [],
    }
  },
  methods: {
    open() {
      this.$refs.refDragAndDropModalBanner.show()
    },
    close() {
      this.$refs.refDragAndDropModalBanner.hide()
    },
    resetList() {
      this.localList = []
    },
    updateList(list) {
      this.localList = [...list]
    },
    onSubmit(bvEvent) {
      bvEvent.preventDefault()

      this.$emit('onConfirm', this.localList)
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100%;
  max-width: 200px;
  border-radius: 1rem;
}

.list-group-item {
  transition: all 0.5s;
}
</style>
