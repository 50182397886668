var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('table-local-v-1-widget',{attrs:{"items":_vm.bannerList,"columns":_vm.columns,"loading":_vm.isLoading,"actions":_vm.actions,"title":"ข้อมูลสไลด์ Banner"},on:{"editBanner":_vm.editBanner,"deleteBanner":_vm.deleteBanner},scopedSlots:_vm._u([{key:"cardHeader",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end mb-50",staticStyle:{"gap":"0.5rem"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"title":"เพิ่มข้อมูล Banner","variant":"flat-primary"},on:{"click":_vm.addBanner}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"title":"จัดการลำดับ Banner","variant":"flat-primary"},on:{"click":_vm.manageSortBanner}},[_c('feather-icon',{attrs:{"icon":"ListIcon"}})],1)],1)]},proxy:true},{key:"id",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":row.id}},[_vm._v(_vm._s(("" + (row.id)).slice(0, 8))+"...")])]}},{key:"image_desktop",fn:function(ref){
var row = ref.row;
return [(row.image_desktop)?_c('div',[_c('img',{staticClass:"banner-image",attrs:{"src":row.image_desktop.url,"alt":""},on:{"click":_vm.gZoomImage}}),_c('br'),_c('small',[_vm._v(_vm._s(row.image_desktop.width)+"x"+_vm._s(row.image_desktop.height))])]):_c('span',[_vm._v("-")])]}},{key:"image_mobile",fn:function(ref){
var row = ref.row;
return [(row.image_mobile)?_c('div',[_c('img',{staticClass:"banner-image",attrs:{"src":row.image_mobile.url,"alt":""},on:{"click":_vm.gZoomImage}}),_c('br'),_c('small',[_vm._v(_vm._s(row.image_mobile.width)+"x"+_vm._s(row.image_mobile.height))])]):_c('span',[_vm._v("-")])]}},{key:"link_to",fn:function(ref){
var row = ref.row;
return [(row.link_to)?_c('a',{attrs:{"href":row.link_to,"target":"_blank"}},[_vm._v(_vm._s(row.link_to))]):_c('span',[_vm._v("-")])]}}])})],1),_c('form-modal-banner',{ref:"refFormModalBanner",on:{"onConfirm":_vm.onModalBannerConfirm}}),_c('drag-and-drop-modal-banner',{ref:"refDragAndDropModalBanner",on:{"onConfirm":_vm.onConfirmSortBanner}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }